'use client';

import HeroArticle from 'components/organisms/heros/hero-article';
import GeoBasedGridArticles from 'components/organisms/listings/grid-articles/geo-based-grid-article';
import { formatDate } from 'date-fns';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { TBreadCrumbItem } from 'helpers/types';
import { generateHashId } from 'helpers/utils/common';
import { tablet } from 'helpers/utils/screensizes';

const GeoBasedGridArticleTastic = ({ data }: any) => {
  const { listItemCurrentState, heroSection, listingItemsCollection } =
    data?.geoBasedGridArticleData?.dataSource?.data?.pageListingPageCollection?.items?.[0] || {};
  const [isTablet] = useMediaQuery(tablet);
  const { listItemDescription, listItemTitle, listItemImages, publishDate, slug, category } =
    listItemCurrentState?.[0] || {};
  const generatedCtaUrl =
    slug?.includes('dispensaries') && !slug?.includes('getting-a-medical-card')
      ? slug + `#${generateHashId('state_faq_section/')}`
      : slug;
  const recommendedResourcesData =
    data?.recommendedResourcesData?.dataSource?.data?.pageListingPageCollection?.items?.[0];
  const image = isTablet ? listItemImages?.desktopImage : listItemImages?.mobileImage || listItemImages?.desktopImage;

  const articleCards = listingItemsCollection?.items?.map((item) => ({
    image: {
      title: isTablet
        ? item?.listItemImages?.desktopImage?.title
        : item?.listItemImages?.mobileImage?.title || item?.listItemImages?.desktopImage?.title,
      url: isTablet
        ? item?.listItemImages?.desktopImage?.url
        : item?.listItemImages?.mobileImage?.url || item?.listItemImages?.desktopImage?.url,
    },
    title: item?.listItemTitle,
    description: item?.listItemDescription,
    articleCardLabel: {
      category: item.category,
      time:
        typeof item?.publishDate === 'number'
          ? formatDate(new Date(Number(item?.publishDate) * 1000), 'MM.dd.yyyy')
          : item?.publishDate,
    },
    url: item?.slug,
  }));

  const breadcrumb: TBreadCrumbItem[] = data?.breadcrumbs?.map((breadcrumb) => {
    return {
      label: breadcrumb?.breadcrumbTitle,
      path: breadcrumb?.slug,
    };
  });

  return (
    <>
      <HeroArticle
        title={heroSection?.title}
        subTitle={heroSection?.description}
        url={image?.url}
        date={publishDate}
        category={category}
        contentTitle={listItemTitle}
        content={listItemDescription}
        alt={image?.title}
        ctaLink={generatedCtaUrl}
        breadcrumbItems={breadcrumb}
        internalName={heroSection?.internalName}
        tasticPlacementNumber={data?.tasticPlacementNumber}
      />
      <GeoBasedGridArticles data={articleCards} recommendedResourcesData={recommendedResourcesData} />
    </>
  );
};

export default GeoBasedGridArticleTastic;
