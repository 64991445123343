import { Breadcrumbs } from '@material-tailwind/react/components/Breadcrumbs';
import { TBreadCrumbItem } from 'helpers/types';
import HomeIcon from 'public/icons/figma/home.svg';
import { Typography } from '../../typography';
import { BreadcrumbMobile, BreadcrumbPrevousIcon, BreadcrumbSeparator } from '../styles';

export interface TProps {
  items: TBreadCrumbItem[];
}

const Breadcrumb = ({ items = [] }: TProps) => {
  const previousItem = items[items.length - 2];
  return (
    <div className="first:[&_nav]:hidden last:[&_nav]:flex first:[&_nav]:lg:flex last:[&_nav]:lg:flex">
      <Breadcrumbs
        aria-label="breadcrumb"
        className="hidden bg-transparent lg:flex"
        separator={<BreadcrumbSeparator />}
        data-testid="breadcrumb-desktop"
      >
        {items.map((item: TBreadCrumbItem, index: number) => {
          const breadcrumbLabel = `Breadcrumb link to: ${item?.label}`;
          const isLastItem = index === items.length - 1;
          return (
            <>
              {isLastItem ? (
                <Typography
                  as={'span'}
                  variant="body-small-bold"
                  className="cursor-auto font-normal !text-grey-400"
                  aria-label={breadcrumbLabel}
                  data-testid="last-breadcrumb"
                  key={`${breadcrumbLabel}-${index}`}
                >
                  {item?.label}
                </Typography>
              ) : (
                <Typography
                  as={'a'}
                  variant="body-small-bold"
                  className="!text-grey-500 hover:underline"
                  aria-label={breadcrumbLabel}
                  href={item?.path || ''}
                  key={`${breadcrumbLabel}-${index}`}
                >
                  {item?.label?.toLowerCase() === 'home' ? (
                    <HomeIcon size={'20px'} className="cursor-pointer text-grey-500 hover:text-grey-500" />
                  ) : (
                    item.label
                  )}
                </Typography>
              )}
            </>
          );
        })}
      </Breadcrumbs>
      <nav>
        <ul>
          <BreadcrumbMobile>
            <BreadcrumbPrevousIcon />
            <Typography
              as={'a'}
              variant="body-small-bold"
              className={'!text-grey-500 hover:underline'}
              aria-label={previousItem?.label}
              href={previousItem?.path || ''}
            >
              {previousItem?.label?.toLowerCase() === 'home' ? (
                <HomeIcon size={'20px'} className="cursor-pointer text-grey-500 hover:text-grey-500" />
              ) : (
                previousItem?.label
              )}
            </Typography>
          </BreadcrumbMobile>
        </ul>
      </nav>
    </div>
  );
};

export default Breadcrumb;
