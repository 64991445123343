'use client';

import React, { useState } from 'react';
import { CommonButton } from 'components/atoms/buttons/common-buttons/button';
import { Typography } from 'components/atoms/typography';
import ArticleCardVertical from 'components/molecules/cards/article-card-vertical';
import RecommendedResources, { TRecommendedResourcesProps } from './recommended-resources';
import {
  GridArticleCardsBox,
  GridArticleCardsBoxFlex,
  GridArticleCardsBoxSpacer,
  GridArticleCardsWrapper,
  GridArticleContainer,
  GridArticlePagination,
} from './styles';

type TProps = {
  data: any;
  recommendedResourcesData: TRecommendedResourcesProps;
};

const GeoBasedGridArticles = ({ data, recommendedResourcesData }: TProps) => {
  const itemsToShowInitially = 6;
  const [itemsToShow, setItemsToShow] = useState(itemsToShowInitially);
  let showButton = itemsToShow < data?.length;
  const remainingCards = data?.length - itemsToShow;
  const handleShowMore = () => {
    const newItemsToShow = Math.min(itemsToShow + itemsToShowInitially, data?.length);
    setItemsToShow(newItemsToShow);
    if (newItemsToShow === data?.length) {
      showButton = false;
    }
  };
  const { colItems } = data?.slice?.(0, itemsToShow)?.reduce(
    (obj, item, index) => {
      const col = index % 2;
      if (col === 1) {
        obj?.colItems?.[obj.colItems.length - 1]?.push(
          <ArticleCardVertical
            ctaCollection={{ title: 'Read More', url: item?.url }}
            title={item?.title}
            description={item?.description}
            key={`${index}-${col}`}
            articleCardLabel={{ category: item.articleCardLabel.category, time: item?.articleCardLabel.time }}
            image={{ url: item?.image?.url, title: item?.image?.title }}
          />,
        );
      } else {
        obj.colItems.push([
          <ArticleCardVertical
            ctaCollection={{ title: 'Read More', url: item?.url }}
            title={item?.title}
            description={item?.description}
            key={`${index}-${col}`}
            articleCardLabel={{ category: item.articleCardLabel.category, time: item?.articleCardLabel.time }}
            image={{ url: item?.image?.url, title: item?.image?.title }}
          />,
        ]);
      }
      return obj;
    },
    { colItems: [] },
  ) || { colItems: [] };
  return (
    <GridArticleContainer>
      <GridArticleCardsBox>
        <Typography variant="h4">{recommendedResourcesData?.bodyHeading}</Typography>
        <GridArticleCardsWrapper>
          {colItems?.map((items, index) => {
            return (
              <GridArticleCardsBoxFlex key={index}>
                {items[0]}
                <GridArticleCardsBoxSpacer />
                {items[1]}
              </GridArticleCardsBoxFlex>
            );
          })}
        </GridArticleCardsWrapper>
        {showButton && (
          <GridArticlePagination>
            <Typography variant="body-small">{`Showing ${itemsToShow} of ${data?.length}`}</Typography>
            <CommonButton onClick={handleShowMore} button-type="secondary" color="green" size="sm">
              {remainingCards > itemsToShowInitially
                ? `Show more (${itemsToShowInitially})`
                : `Show more (${remainingCards})`}
            </CommonButton>
          </GridArticlePagination>
        )}
      </GridArticleCardsBox>
      <RecommendedResources {...recommendedResourcesData} />
    </GridArticleContainer>
  );
};

export default GeoBasedGridArticles;
