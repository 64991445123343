import tw from 'tailwind-styled-components';

export const ArticleCardContainer = tw.div`
    w-full
    2xl:w-[624px]
`;

export const ArticleCardReadMoreButton = tw.div`
    xl:pt-11
    pt-4
`;

export const ArticleCardImageBox = tw.div`
    relative
    w-full
    h-[200px]
    2xl:w-[624px]
    2xl:h-[352px]
    overflow-hidden
`;

export const ArticleCardLabelBox = tw.div`
    absolute
    left-0
    top-0
    m-4
    flex
    gap-2
    xl:m-6
    xl:gap-[10px]
`;

export const ArticleCardContent = tw.div`
    flex
    flex-col
    pt-5
    gap-2
    xl:pt-8
    xl:gap-6
`;

export const ArticleCardContentWrapper = tw.div``;

export const ArticleCardRecipeLabels = tw.div`
    flex
    flex-row
    gap-4
    pb-2
    xl:gap-5
    xl:pb-3
`;
