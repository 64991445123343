import React, { useRef, useState } from 'react';
import Slider from 'react-slick';
import { IconWithTextButton } from 'components/atoms/buttons/icon-button/icon-with-text';
import Pagination from 'components/atoms/pagination';
import { Typography } from 'components/atoms/typography';
import { ContentfulRichText } from 'components/organisms/media/rich-text-component/rich-text-react-render';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { IRichTextData } from 'helpers/types';
import { tablet } from 'helpers/utils/screensizes';
import ChevronRightIcon from 'public/icons/figma/chevron-right.svg';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import {
  DispensaryGuideCardContainer,
  GridArticleDispensaryGuideCards,
  RecommendedResourcesContainer,
  RecommendedResourcesTypography,
} from './styles';

export type TRecommendedResourcesProps = {
  bodyHeading: string;
  recommendedResourcesHeading: string;
  recommendedResourcesDescription: {
    json: any;
  };
  recommendedResourcesCollection: {
    items: {
      listItemTitle: string;
      listItemDescription: IRichTextData;
      slug: string;
    }[];
  };
};

const RecommendedResources = ({
  recommendedResourcesHeading,
  recommendedResourcesDescription,
  recommendedResourcesCollection,
}: TRecommendedResourcesProps) => {
  const slickRef = useRef(null);
  const [isTablet] = useMediaQuery(tablet);
  const [currentItem, setCurrentItem] = useState(0);

  const pageChange = (pageNumber) => {
    // @ts-ignore
    slickRef?.current?.slickGoTo(pageNumber.page - 1);
  };

  const settings = {
    lazyLoading: 'ondemand',
    dots: false,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    speed: 500,
    variableWidth: true,
    afterChange: (index: number) => setCurrentItem(index),
  };

  return (
    <RecommendedResourcesContainer>
      <RecommendedResourcesTypography>
        {recommendedResourcesHeading && <Typography variant="h4">{recommendedResourcesHeading}</Typography>}
        <ContentfulRichText data={recommendedResourcesDescription} />
      </RecommendedResourcesTypography>
      <DispensaryGuideCardContainer>
        {isTablet ? (
          <React.Fragment>
            {recommendedResourcesCollection &&
              recommendedResourcesCollection?.items?.filter(Boolean).map((item, index) => (
                <GridArticleDispensaryGuideCards
                  key={index}
                  $last={index === recommendedResourcesCollection?.items.length - 1}
                >
                  <Typography variant="h5" as="a" href={item?.slug || '/'}>
                    {item?.listItemTitle}
                  </Typography>
                  <Typography variant="body-small" className="[&_div_p]:!pb-0">
                    <ContentfulRichText data={item?.listItemDescription} />
                  </Typography>
                  <IconWithTextButton
                    as="a"
                    color="green"
                    button-type="tertiary"
                    iconPosition="after"
                    icon={<ChevronRightIcon />}
                    className="!px-0"
                    href={item?.slug}
                  >
                    Read More
                  </IconWithTextButton>
                </GridArticleDispensaryGuideCards>
              ))}
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Slider {...settings} ref={slickRef}>
              {recommendedResourcesCollection &&
                recommendedResourcesCollection?.items?.filter(Boolean).map((item, index) => (
                  <GridArticleDispensaryGuideCards
                    key={index}
                    $last={index === recommendedResourcesCollection?.items.length - 1}
                  >
                    <Typography variant="h5" as="a" href={item.slug || '/'}>
                      {item?.listItemTitle}
                    </Typography>
                    <Typography variant="body-small" className="[&_div_p]:!pb-0 [&_div_p]:text-base">
                      <ContentfulRichText data={item?.listItemDescription} />
                    </Typography>
                    <IconWithTextButton
                      as="a"
                      color="green"
                      button-type="tertiary"
                      iconPosition="after"
                      icon={<ChevronRightIcon />}
                      className="!px-0"
                      href={item?.slug}
                    >
                      Read More
                    </IconWithTextButton>
                  </GridArticleDispensaryGuideCards>
                ))}
            </Slider>
          </React.Fragment>
        )}
      </DispensaryGuideCardContainer>
      {!isTablet && (
        <Pagination
          color="leaf"
          isVisible={true}
          count={recommendedResourcesCollection?.items?.filter(Boolean).length}
          variant="large"
          itemsPerPage={1}
          onPageChange={pageChange}
          slickCurrentIndex={currentItem}
        />
      )}
    </RecommendedResourcesContainer>
  );
};

export default RecommendedResources;
