'use client';

import Image from 'next/image';
import { BLOCKS } from '@contentful/rich-text-types';
import { formatDate } from 'date-fns';
import { IconWithTextButton } from 'components/atoms/buttons/icon-button/icon-with-text';
import PublishNewLabel from 'components/atoms/labels/publish-new';
import Label from 'components/atoms/labels/text-and-icon';
import Breadcrumb from 'components/atoms/navigation/breadcrumb/breadcrumb';
import { Typography } from 'components/atoms/typography';
import { ContentfulRichText } from 'components/organisms/media/rich-text-component/rich-text-react-render';
import { bannerTrack } from 'helpers/analytics/banner-track';
import { SegmentEvents } from 'helpers/constants/forms';
import useIntersectionObserver from 'helpers/hooks/use-intersection-observer';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { IRichTextData, TBreadCrumbItem } from 'helpers/types';
import { tablet } from 'helpers/utils/screensizes';
import ChevronRightIcon from 'public/icons/figma/chevron-right.svg';
import {
  HeroArticleButtonSection,
  HeroArticleContainer,
  HeroArticleContentDetail,
  HeroArticleContentSection,
  HeroArticleImageContainer,
  HeroArticleSelfStretch,
  HeroArticleTitleSection,
} from './styles';

interface HeroArticleProps {
  title: string;
  subTitle?: IRichTextData;
  url: string;
  alt: string;
  date: string;
  category: string;
  contentTitle: string;
  content: string | IRichTextData;
  ctaLink: string;
  breadcrumbItems: TBreadCrumbItem[];
  internalName: string;
  tasticPlacementNumber: number;
}

const HeroArticle = ({
  title,
  subTitle,
  url,
  alt,
  date,
  category,
  contentTitle,
  content,
  ctaLink,
  breadcrumbItems,
  internalName,
  tasticPlacementNumber,
}: HeroArticleProps) => {
  const [isTablet] = useMediaQuery(tablet);
  const handleSegmentEvent = (type?: string) => {
    const bannerClickedData = {
      placement: tasticPlacementNumber + 1,
      banner_position: 1,
      banner_internal_name: internalName,
      banner_title: title,
      banner_image_main: url,
      banner_type: 'other',
      link_name: 'Read More',
      link_type: 'hyperlink',
    };
    bannerTrack(type, bannerClickedData);
  };
  const targetRef = useIntersectionObserver(() => {
    handleSegmentEvent(SegmentEvents.BANNER_VIEWED);
  });

  return (
    <HeroArticleContainer ref={targetRef}>
      {(title || subTitle || breadcrumbItems) && (
        <HeroArticleTitleSection>
          {breadcrumbItems && breadcrumbItems.length > 0 && <Breadcrumb items={breadcrumbItems} />}
          {title && (
            <Typography variant="h2" className="text-center lg:self-stretch lg:text-start">
              {title}
            </Typography>
          )}
          {subTitle && (
            <ContentfulRichText
              data={subTitle}
              renderNodeOptions={{
                [BLOCKS.PARAGRAPH]: (node, children) => (
                  <Typography
                    variant="body-large"
                    className="h-auto w-[254px] text-center leading-5 !text-grey-400 md:w-full lg:self-stretch lg:text-start"
                  >
                    {children}
                  </Typography>
                ),
              }}
            />
          )}
        </HeroArticleTitleSection>
      )}
      {(date || category || url || contentTitle || content) && (
        <HeroArticleContentSection>
          <HeroArticleContentDetail>
            {(date || category) && (
              <div className="flex flex-row gap-1 self-stretch md:mb-4">
                {date && <PublishNewLabel date={date} />}
                {category && <Label variant="grey-200">{category}</Label>}
                {category !== 'Resources' && date && (
                  <Label variant="white" className="bg-white">
                    {formatDate(new Date(date), 'MM.dd.yyyy')}
                  </Label>
                )}
              </div>
            )}
            {(contentTitle || content) && (
              <div className="flex-start flex flex-col gap-6 self-stretch md:mb-4">
                {contentTitle && (
                  <Typography variant="h3" className="self-stretch">
                    {contentTitle}
                  </Typography>
                )}
                {content && (
                  <Typography
                    variant="body-large"
                    className="line-clamp-3 self-stretch leading-5 !text-grey-400 md:line-clamp-none [&_div_p]:pb-0"
                  >
                    <ContentfulRichText data={content as IRichTextData} />
                  </Typography>
                )}
              </div>
            )}
            <HeroArticleButtonSection>
              <HeroArticleSelfStretch>
                <IconWithTextButton
                  as="a"
                  color="green"
                  button-type="primary"
                  iconPosition="after"
                  icon={<ChevronRightIcon />}
                  href={ctaLink}
                  onClick={() => handleSegmentEvent(SegmentEvents.BANNER_CLICKED)}
                >
                  {category === 'Resources' ? 'Go to Resource' : 'Read More'}
                </IconWithTextButton>
              </HeroArticleSelfStretch>
            </HeroArticleButtonSection>
          </HeroArticleContentDetail>
          <HeroArticleImageContainer>
            <Image
              src={url}
              alt={alt}
              width={isTablet ? 960 : 767}
              height={isTablet ? 588 : 490}
              loader={(options) => options.src}
            />
          </HeroArticleImageContainer>
        </HeroArticleContentSection>
      )}
    </HeroArticleContainer>
  );
};

export default HeroArticle;
