'use client';

import React from 'react';
import Image from 'next/image';
import { formatDate } from 'date-fns';
import { IconWithTextButton } from 'components/atoms/buttons/icon-button/icon-with-text';
import PublishNewLabel from 'components/atoms/labels/publish-new';
import Label from 'components/atoms/labels/text-and-icon';
import { Typography } from 'components/atoms/typography';
import { ContentfulRichText } from 'components/organisms/media/rich-text-component/rich-text-react-render';
import { IRichTextData } from 'helpers/types';
import { ReplaceUrlWithHost, generateHashId } from 'helpers/utils/common';
import { generateOhioLink } from 'helpers/utils/navigation/generate-oh-link';
import ChevronRightIcon from 'public/icons/figma/chevron-right.svg';
import FoodIcon from 'public/icons/figma/food.svg';
import TimeIcon from 'public/icons/figma/time.svg';
import { useAppSelector } from 'redux/hooks';
import { RootState } from 'redux/store';
import {
  ArticleCardContainer,
  ArticleCardContent,
  ArticleCardContentWrapper,
  ArticleCardImageBox,
  ArticleCardLabelBox,
  ArticleCardReadMoreButton,
  ArticleCardRecipeLabels,
} from './styles';

type TProps = {
  image?: {
    title: string;
    url: string;
  };
  articleCardLabel?: {
    title?: string;
    category?: string;
    time?: string | number;
  };
  title: string;
  description: IRichTextData;
  ctaCollection?: {
    url: string;
    title: string;
  };
  recipeLabel?: {
    time?: string;
    level?: string;
  };
  onLoad?: any;
};

const ArticleCardVertical = ({
  image,
  articleCardLabel,
  title,
  recipeLabel,
  description,
  ctaCollection,
  onLoad,
}: TProps) => {
  const host = useAppSelector((state: RootState) => state?.hostReducer?.host);
  const generatedCtaUrl = ctaCollection?.url + `#${generateHashId('state_faq_section/')}`;
  const ctaUrl =
    ctaCollection?.url?.includes('dispensaries') && !ctaCollection?.url?.includes('getting-a-medical-card');
  const replaceWithHost = ReplaceUrlWithHost(ctaUrl ? generatedCtaUrl : (ctaCollection?.url as string), host);
  const addHostUrl = replaceWithHost?.includes(host) ? replaceWithHost : host + replaceWithHost;
  const newUrl =
    addHostUrl?.includes('ohio') && !host?.includes('oh')
      ? generateOhioLink(host, ctaUrl ? generatedCtaUrl : (ctaCollection?.url as string))
      : addHostUrl;

  const newHostUrl = newUrl?.endsWith('/') ? newUrl : newUrl + '/';

  const target = newHostUrl?.includes(host) ? (newHostUrl?.includes('ohio') ? '_blank' : '_self') : '_blank';
  const articleCardTime = articleCardLabel?.time && formatDate(new Date(articleCardLabel?.time), 'MM.dd.yyyy');
  const category = articleCardLabel?.category ? articleCardLabel?.category : 'Resources';

  return (
    <ArticleCardContainer>
      <ArticleCardImageBox>
        <Image
          onLoad={onLoad}
          layout="fill"
          alt={image?.title || ''}
          loader={(options) => options.src}
          src={image?.url || ''}
          className="h-full w-full rounded-md object-cover"
        />
        <ArticleCardLabelBox>
          <PublishNewLabel date={articleCardTime as string} />
          <Label variant="grey-200">{category}</Label>
          {category !== 'Resources' && articleCardTime && <Label variant="grey-100"> {articleCardTime}</Label>}
        </ArticleCardLabelBox>
      </ArticleCardImageBox>
      <ArticleCardContent>
        <Typography variant="h5" className="w-3/4" as={'a'} href={newHostUrl || '/'} target={target}>
          {title}
        </Typography>
        <ArticleCardContentWrapper>
          {recipeLabel && (
            <ArticleCardRecipeLabels>
              {recipeLabel.time && (
                <Label className="!p-0" icon={<TimeIcon />} variant="white">
                  {recipeLabel.time}
                </Label>
              )}
              {recipeLabel.level && (
                <Label className="!p-0" icon={<FoodIcon />} variant="white">
                  {recipeLabel.level}
                </Label>
              )}
            </ArticleCardRecipeLabels>
          )}
          <Typography
            variant="body-small"
            className="!text-grey-400 [&_div_p]:!pb-0 [&_div_p]:text-base"
            data-testid="description"
          >
            {typeof description === 'string' ? description : <ContentfulRichText data={description} />}
          </Typography>
        </ArticleCardContentWrapper>
      </ArticleCardContent>
      {ctaCollection?.url && ctaCollection?.title && (
        <ArticleCardReadMoreButton>
          <IconWithTextButton
            as="a"
            color="green"
            button-type="tertiary"
            iconPosition="after"
            icon={<ChevronRightIcon />}
            href={newHostUrl}
            className="!p-0"
            target={target}
          >
            {category === 'Resources' ? 'Go to Resource' : ctaCollection?.title}
          </IconWithTextButton>
        </ArticleCardReadMoreButton>
      )}
    </ArticleCardContainer>
  );
};

export default ArticleCardVertical;
