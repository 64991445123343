import tw from 'tailwind-styled-components';

export const HeroArticleContainer = tw.div`
  bg-white
  flex
  flex-wrap
  w-full
  lg:p-[80px_0px]
  lg:flex-col
  lg:items-start
  md:gap-[60px]
  xl:gap-[120px]
`;

export const HeroArticleTitleSection = tw.div`
  flex
  flex-col
  items-center
  gap-4
  w-[390px]
  p-[40px_0px]
  w-full
  lg:p-[0px_60px]
  md:pb-0
  lg:items-start
  lg:gap-6
`;

export const HeroArticleContentSection = tw.div`
  flex
  gap-0
  flex-col-reverse
  md:flex-row
  w-full
  h-fit
`;

export const HeroArticleContentDetail = tw.div`
  bg-grey-100
  flex
  flex-col
  items-center
  justify-between
  p-[40px_20px]
  gap-[24px]
  md:gap-0
  md:p-[40px]
  xl:p-[64px_60px]
  md:w-[50%]
  md:max-h-[588px]
`;

export const HeroArticleImageContainer = tw.div`
  flex
  w-full
  md:max-w-[50%]
  overflow-hidden
`;

export const HeroArticleButtonSection = tw.div`
  flex
  flex-col
  gap-4
  self-stretch
  lg:flex-end
  lg:flex-row
  lg:justify-between
`;

export const HeroArticleSelfStretch = tw.div`
  self-stretch
`;
