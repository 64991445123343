import tw from 'tailwind-styled-components';
interface GuideCardsProps {
  $last: boolean;
}
export const GridArticleCardsBox = tw.div`
    py-10
    xl:py-[120px]
    xl:px-[60px]
    px-5
    flex
    flex-1
    flex-col
    self-stretch
`;

export const GridArticleCardsWrapper = tw.div`
    relative
    flex
    items-start
    self-stretch
    flex-col
    gap-6

    lg:gap-[60px]
    lg:py-8

    xl:gap-24
    py-8
    xl:py-[63px]
`;

export const GridArticleCardsBoxSpacer = tw.div`
    hidden
    h-full
    min-w-[20px]
    max-w-[40px]

    lg:flex

    lg:min-w-[40px]
    lg:max-w-[96px]
    lg:grow
    lg:shrink-0
    lg:basis-0
`;

export const GridArticleCardsBoxFlex = tw.div`
    flex
    flex-start
    flex-col
    gap-6

    lg:flex-row
    lg:gap-0
`;

export const GridArticleContainer = tw.div`
    w-full
    flex
    flex-col
    md:flex-row
    items-start
    justify-between

    lg:pb-[120px]
    pb-0
`;

export const DispensaryGuideCardContainer = tw.div`
    xl:pt-[90px]
    md:pb-0
    py-12
    md:flex
    md:flex-col
`;

export const GridArticleDispensaryGuideCards = tw.div<GuideCardsProps>`
    !flex
    flex-col
    gap-2
    xl:gap-4
    !w-[260px]
    md:!w-full
    pr-4
    mr-4
    border-r
    xl:pb-[42px]
    xl:mb-[42px]
    md:border-b
    md:border-r-0
    border-grey-300
    md:pb-8
    md:mb-8
    md:mr-0
    md:pr-0
    ${(p) => (p.$last ? 'mr-0 pr-0 pb-0 mb-0 border-r-0 md:border-b-0' : '')}
`;

export const GridArticlePagination = tw.div`
    flex
    justify-center
    flex-col
    md:flex-row
    items-center
    gap-4
    md:gap-6
`;

export const RecommendedResourcesTypography = tw.div`
    flex
    flex-col
    xl:gap-3
    gap-2
`;

export const RecommendedResourcesContainer = tw.div`
    py-[50px]
    px-5
    min-w-[270px]
    xl:py-[90px]
    xl:pl-[60px]
    xl:pr-[90px]
    xl:max-w-[364px]
    lg:max-w-[364px]
    md:max-w-[473px]
    bg-grey-100
    overflow-hidden
    md:overflow-visible
    w-full
`;
